import {
  Navbar as NextNavbar,
  NavbarBrand,
  NavbarContent,
} from "@nextui-org/react";
import UserMenu from "~/components/user-menu";
import { Link } from "@remix-run/react";
import LocationPicker from "./location-picker";
import { LuMapPin } from "react-icons/lu";

type NavbarProps = {
  hideLogo?: boolean;
};

export default function Navbar({ hideLogo = false }: NavbarProps) {
  return (
    <>
      {/* Web version */}
      <NextNavbar
        className="hidden md:block"
        isBlurred={false}
        maxWidth="full"
        classNames={{
          wrapper: "px-4 sm:px-6",
        }}
      >
        {!hideLogo && (
          <NavbarBrand>
            <Link to="/">
              <h1 className="text-primary font-black text-3xl">tribe</h1>
            </Link>
          </NavbarBrand>
        )}
        <NavbarContent justify="end">
          <LocationPicker
            buttonProps={{
              radius: "full",
              variant: "bordered",
              startContent: <LuMapPin className="w-4 h-4" />,
              labelClassName:
                "text-ellipsis max-w-xs overflow-hidden whitespace-nowrap",
            }}
          />
          <UserMenu />
        </NavbarContent>
      </NextNavbar>

      {/* Mobile version */}
      <NextNavbar
        className="md:hidden"
        isBlurred={false}
        maxWidth="full"
        classNames={{
          wrapper: "px-4 sm:px-6",
        }}
        // shouldHideOnScroll
        position="static"
      >
        <NavbarBrand>
          {!hideLogo && (
            <Link to="/">
              <h1 className="text-primary font-black text-2xl">tribe</h1>
            </Link>
          )}
        </NavbarBrand>
        <NavbarContent justify="center">
          <LocationPicker
            buttonProps={{
              radius: "full",
              variant: "bordered",
              size: "sm",
              startContent: <LuMapPin className="w-4 h-4 min-w-4" />,
              className: "w-[200px] ml-3",
              labelClassName:
                "text-ellipsis max-w-xs overflow-hidden whitespace-nowrap",
            }}
          />
        </NavbarContent>
        <NavbarContent justify="end">
          <UserMenu />
        </NavbarContent>
      </NextNavbar>
    </>
  );
}
